export const json = {
  "title": "Survey",
  "description": "Please answer a few questions before continuing talking to Serena.",
  "questions": [
   {
      "type": "rating",
      "name": "understand",
      "title": "How well did the chatbot understand your messages?",
      "minRateDescription": "Very poorly",
      "maxRateDescription": "Excellently",
      "isRequired": true
    },      {
      "type": "rating",
      "name": "helpful",
      "title": "Did you find talking to the chatbot helpful?",
      "minRateDescription": "Not helpful",
      "maxRateDescription": "Very helpful",
      "isRequired": true
    }, 
    { 
      "type": "rating",
      "name": "engaging",
      "title": "Was the conversation with the chatbot engaging?",
      "minRateDescription": "Not engaging",
      "maxRateDescription": "Very engaging",
      "isRequired": true
    }, 
    {
      type: "comment",
      name: "suggestions",
      title: "Do you have any suggestions how we can improve Serena?"
    }

  ]
  };
  