import React, { useState, useEffect, useRef, useCallback } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { doc, getDoc, setDoc, updateDoc, collection, addDoc } from "firebase/firestore";
import { info, danger, toxic, wait } from "./toasts"
import { auth, logout, db } from "./firebase";
import TextareaAutosize from 'react-textarea-autosize';
import paperplane from "./assets/paper2_graham.png"
import { ToastContainer } from 'react-toastify';
import update from 'immutability-helper';
import { BsList } from 'react-icons/bs';
import ReactGA from "react-ga4";
import Sidebar from "react-sidebar";
import DashboardMenu from "./Sidebar";
import Modals from "./Modals";
import { StatusBar } from '@capacitor/status-bar';
import { removeUser } from "./firebase";
import { getSubscribeInfo, handleManageSubscription } from './Subscribe';


import { Keyboard } from '@capacitor/keyboard';
import { SafeArea } from '@aashu-dubey/capacitor-statusbar-safe-area';
import { isChrome } from 'react-device-detect';

import { accent_color, num_free_messages, num_standard_messages } from "./variables.js"

import 'react-toastify/dist/ReactToastify.css';
import './css/Dashboard.css';
import './css/index.css';


import { Capacitor } from "@capacitor/core"

const getStatusBarHeight = async () => {
  const { height } = await SafeArea.getStatusBarHeight();
  return height;
};

// const backend_url = "https://fastapi.marketinference.com"
const backend_url = "https://grahamapi.marketinference.com"


// small adjustments to the layout depending on the platform
let topmargin
let buttonMargin
let heightHeader
let position
let platform
let scroll
let heightBody

if (Capacitor.isNativePlatform()) {
  Keyboard.setResizeMode({ mode: 'native' });
  platform = Capacitor.getPlatform();
  if (platform === "ios") {
    getStatusBarHeight().then((val) => {
      topmargin = 0.9 * val;
      if (window.screen.availHeight < 1000) {
        heightHeader = 0.11 * window.screen.availHeight
      }
      else {
        heightHeader = 0.06 * window.screen.availHeight
      }

      position = "relative"
      buttonMargin = "0px"
      scroll = "smooth"
      heightBody = "100vh"
    })
  }
  // android
  else {
    StatusBar.setBackgroundColor({ color: "#696fe2" });
    topmargin = "0px"
    heightHeader = 0.05 * window.screen.availHeight
    position = "sticky"
    buttonMargin = "0rem"
    scroll = "auto"
    heightBody = "100vh"
  }

}
// web
else {
  topmargin = "0px"
  heightHeader = "3rem"
  buttonMargin = "0px"
  position = "sticky"
  scroll = "auto"
  if (isChrome) {
    heightBody = "calc(100vh - 3.5rem)"
  }
  else {
    heightBody = "calc(100dvh)"
  }
}
ReactGA.initialize("G-WV7T86637X");

const subscribe_active = true


function Dashboard() {
  const [text, setText] = useState("");
  const [input, setInput] = useState("");
  const [messages, setMessage] = useState([]);
  const [width, setWidth] = useState("80vw");

  const [receiving, setReceiving] = useState(false);
  const [log, setLog] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSignModal, setShowSignModal] = useState(false);
  const [userStatus, setUserStatus] = useState("user");

  const [subscribeStatus, setSubscribeStatus] = useState("")
  const [subscribeTier, setSubscribeTier] = useState("")
  const [standardUsage, setStandardUsage] = useState(0)

  const [stripeId, setStripeId] = useState("");
  const [total_msg, setTotalMsg] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const [data, setData] = useState([]);

  const message_id = useRef(0);
  const date = new Date().toLocaleDateString('fr-CH');
  const height = "100vh"

  const navigate = useNavigate();
  const [user, loading] = useAuthState(auth);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const containerRef = useRef(null);
  const inputRef = useRef(null);
  const [columns, setColumns] = useState(null);

  const api_key = process.env.REACT_APP_API_KEY;

  const welcome_msg = {user: "bot", id:0, text: "Hello, how can I assist you today?"};

  const onSetSidebarOpen = (open) => {
    setSidebarOpen(open);
  };

  const toastId = React.useRef(null);

  const mediaMatch = window.matchMedia('(max-device-width: 1100px)');
  const [onMobile, setOnMobile] = useState(mediaMatch.matches);
  //todo: reduce amount of firebase calls

  useEffect(() => {
    const handler = e => setOnMobile(e.matches);
    mediaMatch.addEventListener("change", handler);
    return () => mediaMatch.removeEventListener("change", handler);
  });

  useEffect(() => {
    if (onMobile) {
      setWidth("100vw");
    }
  }, [onMobile]);

  const updateSubscribeInfo = useCallback(async (user) => {
    const subscribeInfo = await getSubscribeInfo(user);
    setSubscribeStatus(subscribeInfo.subscribe_status);
    setSubscribeTier(subscribeInfo.subscribe_tier);
    setStandardUsage(subscribeInfo.standard_usage);
  }, []);
  //update subscriptions status whenever subscrition modal or sidebar opens or closes
  useEffect(() => {
    getSubscribeInfo();
  }, [showSubscriptionModal, updateSubscribeInfo, sidebarOpen]);

  const fetchUserData = useCallback(async (user, columns) => {
    if (!user) return;
    const userRef = doc(db, "users", user?.uid);
    // retry three times to get data from firestore, initial load can be very slow
    for (let i = 0; i < 3; i++) {
      try {
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          const name = docSnap.data()["name"];
          setUserStatus(docSnap.data()["status"])
          updateSubscribeInfo(user)
          setTotalMsg(docSnap.data()["total_msg"]);
          if (subscribe_active) {
            setStripeId(docSnap.data()["stripeId"])
          }

          if (name === "__init") {
            continue;
          };
          setLog(docSnap.data()["archive_anon"]);
          const history = docSnap.data()["history"];
          if (history === "") {
            // create a dictionary with keys as defined by columns and values as empty strings
            const init_data = columns.reduce((obj, key) => ({ ...obj, [key]: "" }), {});
            setMessage([welcome_msg]);
            setData([init_data]);
          }
          else {
            // extract the prompts and answer and parse them into a list of messages
            const newMessages = data_to_message(history);
            setMessage([welcome_msg].concat(newMessages));
            setData(history);
          }
          return
        } else {
          console.log("No such document!");
          //sleep for two second and retry
          await new Promise(r => setTimeout(r, 1000));
        }
      }
      catch (error) {
        console.log("failed, retrying. Error:", error);
      }
      await new Promise(r => setTimeout(r, 2000));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkFirstTime = useCallback(async (user) => {
    const userRef = doc(db, "users", user?.uid);
    const docSnap = await getDoc(userRef);
    if (docSnap.exists()) {
      const total_msg = docSnap.data()["total_msg"];
      if (total_msg === 0) {
        if (user.emailVerified) {
          await updateDoc(userRef, { "verified": true });
        }
        return true;
      }
      else return false
    } else {
      console.log("No such document!");
    }
  }, []);

  // this is used to decide whether the survey should be triggered
  // only show survey if user does not have an active subscription
  const checkSurvey = useCallback(async (user) => {
    const userRef = doc(db, "users", user?.uid);
    const docSnap = await getDoc(userRef);
    if (docSnap.exists()) {
      const total_msg = docSnap.data()["total_msg"];
      const survey = docSnap.data()["survey"];
      if (total_msg > 25 && survey === "" && subscribeStatus !== "active") return true;
      else return false
    } else {
      console.log("No such document!");
    }
  }, [subscribeStatus]);

  // check if the metric document has been created today already, if not do so
  const initMetric = useCallback(async () => {
    const date = new Date().toLocaleDateString('fr-CH');
    const respRef = doc(db, "metrics", date);
    const respSnap = await getDoc(respRef);
    if (respSnap.exists() === false) {
      await setDoc(respRef, { "max": 0, "mean": 0, "num": 0, "users": 0 });
    }
  }, []);

  const data_to_message = (data) => {
    const newMessages = [];
    var id = 1;
    for (let i = 0; i < data.length; i++) {
      const prompt = data[i]["Prompt"];
      const answer = data[i]["Answer"];
      if (prompt !== "") {
        newMessages.push({ user: "user", text: prompt, id: id });
        id++;
      }
      if (answer !== "") {
        newMessages.push({ user: "bot", text: answer, id: id });
        id++;
      }
    }
    return newMessages;
  }

  const handleManageSubscriptionCallback = useCallback(async (user) => {
    await handleManageSubscription(setIsLoading, user, stripeId, backend_url, fetchWithTimeout);
  }, [stripeId]);

  useEffect(

    function initialization() {
      if (loading) return;
      if (!user) return navigate("/");
      
      async function getColumns() {
        const res = await fetch(`${backend_url}/columns`, {method: "GET"})
        const res_json = await res.json()
        return res_json.columns
      }
      getColumns()
      .then(res => {
        const columns = res; 
        setColumns(columns);
        fetchUserData(user, columns).catch(error => console.log(error));
      })
      .catch(error => {console.log(error)})
      checkFirstTime(user).then(check => {
        if (check) {
          setShowInfoModal(true)
        }
      }).catch(error => console.log(error));
      
      initMetric().catch(error => console.log(error));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, loading, fetchUserData, checkFirstTime, initMetric]);

  const throwOnTimeout = (timeout) =>
    new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout')), timeout))

  const fetchWithTimeout = (url, options = {}) => {
    const { timeout, ...remainingOptions } = options
    // if the timeout option is specified, race the
    // fetch call
    if (timeout) {
      return Promise.race([fetch(url, remainingOptions), throwOnTimeout(timeout)])
    }
    return fetch(url, remainingOptions)
  }

  const processResponse = async () => {

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'access_token': api_key,
      },
      body: JSON.stringify({ uid: user?.uid, data: data })
    };
    // const api_url = 'https://grahamapi.marketinference.com/respond'
    const api_url = `${backend_url}/respond`
    setReceiving(true);
    const res = await fetch(api_url, requestOptions);
    const resJson = await res.json();
    const updatedData = [...data.slice(0, data.length - 1), resJson.answer];
    setReceiving(false);
    setData(updatedData);
    setMessage([welcome_msg].concat(data_to_message(updatedData)));
    const docRef = doc(db, "users", user?.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      await updateDoc(docRef, { "history": updatedData });
    }
  }


  // with this hook we fetch a response from the API
  useEffect(() => {
    async function getResponse() {
      if (text === "") return;
      const userRef = doc(db, "users", user?.uid);
      const userSnap = await getDoc(userRef);
      const last_msg = messages.find(item => item.id === messages.length - 1);
      if (userSnap.exists()) {
        if (text !== "" && last_msg.user === 'user') {
          if (!subscribe_active) {
            checkSurvey(user).then((check) => {
              if (check) navigate("/survey")
            }
            ).catch(error => console.log(error));
          }
          const t0 = performance.now();
          processResponse()

          const t1 = performance.now();
          const latency = Math.round(t1 - t0);
          const metricRef = doc(db, "metrics", date);
          const metricSnap = await getDoc(metricRef);

          // initialize metric document if it does not exist yet
          if (metricSnap.exists() === false) {
            await setDoc(metricRef, { "max": 0, "mean": 0, "num": 0, "users": 0 });
          }

          // Check if msg_cnt exists for today, if not set it up and increase number of daily users by one
          if (userSnap.data()["msg_cnt"][date] === undefined) {
            await updateDoc(userRef, { "msg_cnt": { [date]: 0 } })
            await updateDoc(metricRef, { "users": metricSnap.data()["users"] + 1 })
          }

          // log send message with google analytics
          // only count messages that are not retry or init and only for users with activated logging
          if (log) {
            ReactGA.event({
              category: "Messages",
              action: "Message sent"
            });
          }

          const new_mean = Math.round((metricSnap.data()["mean"] + latency) / 2);
          var new_num = metricSnap.data()["num"]
          // only count messages for users with activated logging
          if (log) {
            new_num += 1;
          }

          var max = 0
          if (latency > metricSnap.data()["max"]) {
            max = latency;
          }
          else {
            max = metricSnap.data()["max"]
          }
          await updateDoc(metricRef, { "max": max, "mean": new_mean, "num": new_num })
            .catch(error => { console.log(error) });
          setReceiving(false);
          // console.log(`Time to fetch answer: ${latency} ms`);
        }
      }
    }
    getResponse().catch(error => {
      const newArray = update(messages, { $push: [{ id: messages.length, text: 'Sorry, an error occured and we couldn\'t get a response. Please regenerate the response.', user: "bot" }] });
      setMessage(newArray);
      setReceiving(false);
      console.log("error", error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message_id.current]);

  const updateMessages = () => {
    const newPrompt = columns.reduce((obj, key) => ({ ...obj, [key]: "" }), {});
    newPrompt["Prompt"] = input;
    setInput("");
    // if this is the first prompt, set the data to be the new prompt
    var newData = [];
    if (data.length === 1 && data[data.length -1].Answer === "") {
      newData = [newPrompt];
    }
    else {
      newData = [...data, newPrompt];
    }
    setData(newData);
    setMessage([welcome_msg].concat(data_to_message(newData)));
  }

  const handleSubmit = async (event) => {
    // this prevents the keyboard from closing after user submits message
    inputRef.current.focus();
    if (subscribe_active && total_msg >= num_free_messages && subscribeStatus !== "active" && userStatus !== "admin") {
      setShowSubscriptionModal(true)
    }
    else if (subscribe_active && standardUsage >= num_standard_messages && subscribeTier === "Standard Plan" && subscribeStatus === "active" && userStatus !== "admin") {
      setShowSubscriptionModal(true)
    }
    else {
      if (receiving === false) {
        if (input !== "") {
          setReceiving(true)
          setText(input)
          event.preventDefault();
          message_id.current = message_id.current + 1;
          updateMessages()
          const userRef = doc(db, "users", user?.uid);
          const docSnap = await getDoc(userRef);
          if (docSnap.exists()) {
            setTotalMsg(docSnap.data()["total_msg"] + 1);
            await updateDoc(userRef, { "total_msg": docSnap.data()["total_msg"] + 1 });
            await updateDoc(userRef, { "msg_cnt": { [date]: docSnap.data()["msg_cnt"][date] + 1 } });
            if (subscribe_active && subscribeStatus === "active" && subscribeTier === "Standard Plan") {
              await updateDoc(userRef, { "standard_usage": docSnap.data()["standard_usage"] + 1 });
            }
          }
        }
      }
      else {
        wait(toastId);
      }
    }
    updateSubscribeInfo(user)
  };

  // on retry: remove last answer by bot and query new one 
  const handleRetry = event => {
    // keep the keyboard open if it is already open
    // check if keyboard open
    if (window.innerHeight / window.screen.availHeight < 0.8) {
      inputRef.current.focus();
    }
    event.preventDefault();
    if (messages.length > 2 && receiving === false) {
      setReceiving(true)
      const last_msg = messages.find(item => item.id === messages.length - 1);
      //only allow to delete last message by bot
      if (last_msg.user === 'bot') {
        message_id.current = message_id.current + 1;
        setMessage(messages.filter(item => item.id !== messages.length - 1));
        // this handles the case when user tries to regenerate a response after refreshing the page
        if (text === "") {
          setText(messages[messages.length - 2].text);
        }
      }
    }
  };

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      handleSubmit(e);
    }
  }

  const reset = async () => {
    setReceiving(true);
    setMessage([]);
    try {
      const userRef = doc(db, "users", user?.uid);
      if (log) {
        await addDoc(collection(db, "archive"), {
          date: date,
          history: messages
        })
      }
      await updateDoc(userRef, { "history": "" });
      fetchUserData(user, columns);
      setReceiving(false);
    }
    catch (error) {
      console.log("Problem with updating Firestore: ", error)
    }
  }

  const handleInfo = () => {
    info(toastId);
  }

  const textclick = () => {
    setClickCount(clickCount + 1);
  }

  const deleteAcc = async () => {

    // often deleting the account fails because firebase complains that the user did not recently sign in
    // we therefore check if the login is older than one minute and if so, we let the user sign in again
    const lastSignInTime = new Date(user.metadata.lastSignInTime);
    const currentTime = new Date();
    const timeDiff = (currentTime - lastSignInTime) / 1000; // Convert to seconds
    if (timeDiff > 60) {
      setShowDeleteModal(false)
      setShowSignModal(true)
    }
    else { await removeUser(user) }
  }

  if (!onMobile) {
    // This is return on large displays 
    return (
      <section style={{ backgroundColor: "#eee" }}>
        <div className="container-fluid fill" style={{ height: height, width: width, background: "#eee" }}>
          <Sidebar
            sidebar={
              <DashboardMenu handleInfo={handleInfo} reset={reset} logout={logout} setShowSubscriptionModal={setShowSubscriptionModal} setShowInfoModal={setShowInfoModal} subscribe_active={subscribe_active} user={user} setShowDeleteModal={setShowDeleteModal} />
            }
            open={sidebarOpen}
            onSetOpen={onSetSidebarOpen}
            styles={{ sidebar: { background: accent_color, width: "250px" } }}
          >
            <div className="row justify-content-center">
              <div className="col-md-10 col-lg-8 col-xl-6">
                <div className="card border-0" id="chat2" style={{ borderRadius: 15, top: "10vh" }}>
                  <div className="card-header d-flex justify-content-between align-items-center p-3" style={{ backgroundColor: accent_color, borderTopLeftRadius: 15, borderTopRightRadius: 15 }}>
                    <div className="text-center">
                      {/* <h5 className="mb-0 text-white">Serena</h5> */}
                      <span className="logo me-auto" style={{ marginLeft: "0px" }}>
                        <strong className="h1title">GRAHAM</strong></span>
                    </div>
                    <div className="text-right">
                      <button onClick={() => setSidebarOpen(true)} style={{ background: accent_color, color: "white", border: "none", borderRadius: "5px", padding: "10px" }}>
                        <BsList size={25} />
                      </button>
                    </div>
                  </div>
                  <div
                    className="card-body"
                    data-mdb-perfect-scrollbar="true"
                    style={{ position: "relative", height: "55vh", overflow: "auto" }}
                  >
                    {/* {messages.length > 0 ? <MessageList items={messages} /> : <Typing />} */}
                    <MessageList items={messages} />
                    <ToastContainer />
                    <div className="d-flex justify-content-center align-content-center">
                      {(messages.length % 2 !== 0 && messages.length > 2 && receiving === false) ? <button type="button" className="btn btn-outline-dark btn-sm rounded2 border-1 hover-overlay" onClick={handleRetry} style={{ position: "relative", top: -10, fontSize: 12 }}>Regenerate response</button> : <div></div>}
                    </div>
                  </div>
                  {/* backgroundColor: "#ffffff", borderColor: "#9B9B9B", color: "#636363", */}
                  <div className="card-footer text-muted d-flex justify-content-start align-items-center p-3" style={{ backgroundColor: "#fff", borderBottomLeftRadius: 15, borderBottomRightRadius: 15 }}>
                    <TextareaAutosize
                      className="form-control form-rounded"
                      onKeyDown={onEnterPress}
                      value={input}
                      onChange={event => setInput(event.target.value)}
                      id="exampleFormControlInput1"
                      placeholder="Type message"
                      rows="1"
                      style={{ overflow: "auto", height: "auto" }}
                      ref={inputRef}>
                    </TextareaAutosize>
                    <button type="button" className="noborder buttonspace" onClick={handleSubmit}><img src={paperplane} width="32" height="32" alt="" /></button>
                  </div>
                </div>
              </div>
            </div>
          </Sidebar>
        </div>
        <Modals showSubscriptionModal={showSubscriptionModal} setShowSubscriptionModal={setShowSubscriptionModal} total_msg={total_msg} handleManageSubscription={handleManageSubscriptionCallback} showInfoModal={showInfoModal} setShowInfoModal={setShowInfoModal} isLoading={isLoading} showDeleteModal={showDeleteModal} setShowDeleteModal={setShowDeleteModal} deleteAcc={deleteAcc} showSignModal={showSignModal} setShowSignModal={setShowSignModal} />
      </section>
    );
  }
  else {
    //
    //
    //
    //
    //
    // this is return on small, mobile devices
    //
    //
    //
    //
    return (
      <div>
        <Sidebar
          sidebar={
            <DashboardMenu handleInfo={handleInfo} reset={reset} logout={logout} setShowSubscriptionModal={setShowSubscriptionModal} setShowInfoModal={setShowInfoModal} subscribe_active={subscribe_active} user={user} setShowDeleteModal={setShowDeleteModal} />
          }
          open={sidebarOpen}
          onSetOpen={onSetSidebarOpen}
          styles={{ sidebar: { background: accent_color, width: "250px" } }}
        >
          <div className="card border-0 p-0 d-flex" id="chat2" style={{ borderRadius: 0, top: "0", height: heightBody }}>

            <div className="card-header border-0 d-flex justify-content-between align-items-center p-3" style={{ borderRadius: 0, backgroundColor: accent_color, height: heightHeader, position: position, top: "0", left: "0", right: "0", zIndex: "300" }}>
              <div className="text-center" style={{ marginTop: topmargin }}>
                <span className="logo me-auto">
                  <strong className="h1titlemobile">GRAHAM</strong></span>
              </div>
              <button onClick={() => setSidebarOpen(true)} style={{ background: accent_color, color: "white", border: "none", borderRadius: "5px", padding: "5px", marginTop: topmargin }}>
                <BsList size={28} />
              </button>
            </div>

            <div ref={containerRef} className="card-body pb-2 flex-grow-1 flex-col" data-mdb-perfect-scrollbar="true" style={{ position: "relative", overflow: "auto", height: "100vh" }}>
              <MessageList items={messages} clickCount={clickCount} />
              <ToastContainer />
              {/* This empty div adds space to the bottom of the chat while receiving a message */}
              <div className="d-flex justify-content-center align-content-center">
                {(messages.length % 2 !== 0 && messages.length > 2 && receiving === true) ? <div style={{ position: "relative", top: -10, fontSize: 12, marginBottom: buttonMargin }}></div> : <div></div>}
              </div>
              <div className="d-flex justify-content-center align-content-center">
                {(messages.length % 2 !== 0 && messages.length > 2 && receiving === false) ? <button type="button" className="btn btn-outline-dark btn-sm rounded2 border-1 hover-overlay" onClick={handleRetry} style={{ position: "relative", top: -10, fontSize: 12, marginBottom: buttonMargin }}>Regenerate response</button> : <div></div>}
              </div>
            </div>
            <div className="card-footer text-muted d-flex justify-content-start align-items-center p-3" style={{ position: position, height: "3.5rem", bottom: "0", backgroundColor: "#fff", borderTop: "none" }}>
              <TextareaAutosize
                // type="text"
                className="form-control form-rounded input"
                onKeyDown={onEnterPress}
                onClick={textclick}
                value={input}
                onChange={event => setInput(event.target.value)}
                id="exampleFormControlInput1"
                placeholder="Type message"
                rows="1"
                style={{ overflow: "auto", height: "auto", backgroundColor: "#f5f6f7", borderRadius: "1.5rem" }}
                ref={inputRef}>
              </TextareaAutosize>
              <button type="button" className="noborder buttonspace" onClick={handleSubmit}><img src={paperplane} width="32" height="32" alt="send button" /></button>
            </div>
            <Modals showSubscriptionModal={showSubscriptionModal} setShowSubscriptionModal={setShowSubscriptionModal} total_msg={total_msg} handleManageSubscription={handleManageSubscriptionCallback} showInfoModal={showInfoModal} setShowInfoModal={setShowInfoModal} isLoading={isLoading} showDeleteModal={showDeleteModal} setShowDeleteModal={setShowDeleteModal} deleteAcc={deleteAcc} showSignModal={showSignModal} setShowSignModal={setShowSignModal} setSidebarOpen={setSidebarOpen} />
          </div>
        </Sidebar>
      </div>
    );
  }
}



function Message(props) {
  if (props.item.user === "bot") {
    return (
      <div className="d-flex flex-row justify-content-start mb-2" style={{ marginLeft: -30 }}>
        <div>
          <p
            className="small p-2 mb-1 ms-2 rounded1"
            style={{ backgroundColor: "#f5f6f7" }}
          >
            <span >{props.item.text}</span>
          </p>
        </div>
      </div>
    )
  }
  if (props.item.user === "user") {
    return (
      <div className="d-flex flex-row justify-content-end mb-2">
        <div>
          <p className="small p-2 me-2 mb-1 text-white rounded1" style={{ backgroundColor: accent_color }}>
            {props.item.text}
          </p>
        </div>
      </div>
    )
  }
}

const MessageList = React.memo( function MessageList(props) {
  const num_messages = props.items.length;
  const [isTyping, setTyping] = useState(true);
  const newRef = useRef();

  let bodyPaddingTop
  let marginBottomTyping
  if (Capacitor.isNativePlatform()) {
    const platform = Capacitor.getPlatform();
    Keyboard.addListener('keyboardDidShow', info => {

      if (newRef && newRef.current) {
        newRef.current.scrollIntoView({ behavior: scroll });
      }
    });
    if (platform === "ios") {
      bodyPaddingTop = "0px"
      marginBottomTyping = "0px"
    }
    else {
      bodyPaddingTop = "0rem"
      marginBottomTyping = "0px"
    }
  }
  else {
    bodyPaddingTop = "0px"
    marginBottomTyping = "0px"
  }

  // check if last message is by user, in which case display typing animation
  useEffect(() => {
    const lastuser = props.items[props.items.length - 1]?.user;
    if (lastuser === "bot") setTyping(false);
    if (lastuser === "user") setTyping(true);
    if (num_messages === 0) setTyping(true);
    // eslint-disable-next-line
  }, [props]);

  const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => {
      elementRef.current.scrollIntoView({ behavior: scroll });
    });
    return <div ref={elementRef} />;
  };

  const Typing = () => (
    <div className="typing" style={{ marginBottom: marginBottomTyping }}>
      <div className="typing__dot"></div>
      <div className="typing__dot"></div>
      <div className="typing__dot"></div>
    </div>
  )

  return (
    <ul className="message-list" style={{ paddingTop: bodyPaddingTop }}>
      {props.items.map(item => (
        <li ref={newRef} key={item.id} className="nobull">
          <Message item={{ user: item.user, text: item.text }} />
        </li>
      ))}

      {isTyping ? (<Typing />) : null}
      {num_messages > 1 ? <AlwaysScrollToBottom /> : null}
    </ul>
  )
})
export default Dashboard;