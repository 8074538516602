import React, { useEffect, useState, useCallback } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import {
  auth,
  registerWithEmailAndPassword,
  signInWithApple,
  signInWithGoogle
} from "./firebase";
import "./css/Register.css";
import "./css/Login.css";
import "./Login";
import googleButton from "./assets/google2.png"
import appleButton from "./assets/apple.png"
import appleButtonFull from "./assets/applebutton.png"
import googleButtonFull from "./assets/googleButton.png"
import { Capacitor } from "@capacitor/core"
import { SafeArea } from '@aashu-dubey/capacitor-statusbar-safe-area';


import { accent_color } from "./variables.js"


const getStatusBarHeight = async () => {
  const { height } = await SafeArea.getStatusBarHeight();
  return height;
};

let headMargin
let signInTopMargin
let platform
let position
if (Capacitor.isNativePlatform()) {
  platform = Capacitor.getPlatform();
  position = "relative"
  getStatusBarHeight().then((val) => {
    headMargin = val;
    if (platform === "ios") {
      signInTopMargin = val + 0.12*window.screen.availHeight
    }
    else{
      signInTopMargin = 0.2*window.screen.availHeight
    }
  })
}
else {
  position = "fixed"
  signInTopMargin = 0.1*window.screen.availHeight
  headMargin = "0px"
  platform = "web"
}

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading] = useAuthState(auth);
  const [marginForm, setMarginForm] = useState("0px");
  const navigate = useNavigate();

  const mediaMatch = window.matchMedia('(max-width: 1000px)');
  // matches = True means we are on a display with small width
  const [matches, setMatches] = useState(mediaMatch.matches);
  const [margin, setMargin] = useState("-7.5px");


  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addEventListener("change", handler);
    return () => mediaMatch.removeEventListener("change", handler);
  });

  useEffect(() => {
    if (matches) {
      setMargin("0px");
      setMarginForm("-20px");
    }
  }, [matches]);

  const register = () => {
    registerWithEmailAndPassword(email, password).then((val) => {
      if (val === "success") {
        alert("Your account has been created. To login, please verify your email address by clicking on the link we have sent you.");
        navigate("/");
      }
    }).catch(error => console.log("error", error));
    
  };

  const loginGoogle = useCallback(async () => {
    await signInWithGoogle(email, password).then((val) => {
      if (user) {
        console.log("here")
        navigate("/dashboard");
      }
    });
  }, []);

  const loginApple = useCallback(async () => {
    await signInWithApple();
    if (user) {
      navigate("/dashboard");
    }
  }, []);

  useEffect(() => {
    if (loading) return;
    if (user) {
      if (user.emailVerified) navigate("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, loading]);

  return (
    <div class="full-bleed">
      <header id="header" className="fixed-top"  style={{backgroundColor: accent_color}}>
        <div className="container d-flex align-items-center" style={{backgroundColor: accent_color}}>
          <a href="https://serena.chat" className="logo me-auto" style={{ marginLeft: margin, marginTop: headMargin }}>
            <strong className="h1title">Graham</strong></a>
        </div>
      </header>

      <div className="container ex-2-header" style={{ position: position }}>
        <div className="row">
          <div className="col-lg-12" style={{ position: position, top: signInTopMargin }}>
            <h1>Sign Up</h1>
            <p>
              <span className="bodymyfont">
                Already signed up? Then
                just{" "}
                <a className="white bodymyfont" href="./">
                  Sign in
                </a></span>
            </p>
            {/* Sign Up Form */}
            <div className="form-container" style={{ "marginTop": marginForm }}>
              <div className="form-group">
              {platform === "web" ?
                    <div>
                      <button
                        style={{
                          backgroundImage: `url(${googleButton})`,
                          backgroundPosition: 'center',
                          backgroundSize: '60% auto',
                          backgroundRepeat: 'no-repeat',
                          backgroundColor: 'white',
                        }}
                        className="form-control-submit-button buttonmyfont my-button" onClick={() => loginGoogle()}>
                      </button>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <button
                        style={{
                          backgroundImage: `url(${appleButton})`,
                          backgroundPosition: 'center',
                          backgroundSize: '120% auto',
                          backgroundRepeat: 'no-repeat',
                          backgroundColor: 'white',
                        }}
                        className="form-control-submit-button buttonmyfont my-button" onClick={() => loginApple()}>
                      </button>
                    </div> : <div>{platform === "ios" ? <button
                        style={{
                          backgroundImage: `url(${appleButtonFull})`,
                          backgroundPosition: 'center',
                          backgroundSize: '60% auto',
                          backgroundRepeat: 'no-repeat',
                          backgroundColor: 'black',
                        }}
                        className="form-control-submit-button buttonmyfont my-button-solo" onClick={() => loginApple()}>
                      </button>: <button
                        style={{
                          backgroundImage: `url(${googleButtonFull})`,
                          backgroundPosition: 'center',
                          backgroundSize: '60% auto',
                          backgroundRepeat: 'no-repeat',
                          backgroundColor: 'white',
                        }}
                        className="form-control-submit-button buttonmyfont my-button-solo-google" onClick={() => loginGoogle()}>
                      </button>}</div>}
                <div className="line-with-text" style={{marginBottom: "6px"}}>
                  <span>or</span>
                </div>              
              <div className="form-group">
                <input
                  type="email"
                  className="form-control-input inputmyfont"
                  id="semail"
                  required=""
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  // onClick={() => checkKeyboard(inputRef, passwordRef, signInTopMargin, setTopMargin)}
                  // ref={inputRef}
                />
                <div className="help-block with-errors" />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control-input inputmyfont"
                  id="spassword"
                  required=""
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  // onClick={() => checkKeyboard(inputRef, passwordRef, signInTopMargin, setTopMargin)}
                  // ref={passwordRef}
                />
                <div className="help-block with-errors" />
              </div>
              <div className="form-group checkbox checkboxes">
              </div>
              <div className="form-group">
                <button className="form-control-submit-button buttonmyfont" onClick={register}>
                  Sign up with email
                </button>
              </div>
              </div>
              <div className="form-message">
                <div id="smsgSubmit" className="h3 text-center hidden" />
              </div>
              {/* <a className="white" href="http://localhost:8000" style={{ color: "#696fe2", "font-size": "14px" }} >
              Go back
            </a> */}
            </div>{" "}

            {/* end of form container */}
            {/* end of sign up form */}
          </div>{" "}
          {/* end of col */}
        </div>{" "}
        {/* end of row */}
      </div>{" "}
      {/* end of container */}

    </div>
  );
}

export default Register;