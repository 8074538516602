import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { auth, logInWithEmailAndPassword, signInWithGoogle, signInWithApple, db } from "./firebase";
import { doc, getDoc } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import logorounded from "./assets/icon-512.webp"
import googleButton from "./assets/google2.png"
import appleButton from "./assets/apple.png"
import appleButtonFull from "./assets/applebutton.png"
import googleButtonFull from "./assets/googleButton.png"
import LoadingAnimation from "./Loading"
import { Capacitor } from "@capacitor/core"
import { SafeArea } from '@aashu-dubey/capacitor-statusbar-safe-area';
// import { checkKeyboard } from "./utils";

import WebFont from 'webfontloader';
import "./css/Login.css";
import "./css/Register.css";

import "./Register"

import { accent_color } from "./variables.js"


const getStatusBarHeight = async () => {
  const { height } = await SafeArea.getStatusBarHeight();
  return height;
};

let headMargin
let signInTopMargin
let platform
let position
if (Capacitor.isNativePlatform()) {
  platform = Capacitor.getPlatform();
  position = "relative"
  getStatusBarHeight().then((val) => {
    
    if (platform === "ios") {
      headMargin = val;
      signInTopMargin = val + 0.12 * window.screen.availHeight
    }
    else {
      headMargin = 0
      signInTopMargin = 0.2 * window.screen.availHeight
    }
  })

}
else {
  position = "fixed"
  signInTopMargin = 0.1 * window.screen.availHeight
  headMargin = "0px"
  platform = "web"
}

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading] = useAuthState(auth);
  // this triggers showing a loading animation after the user logged in
  const [loginLoad, setLoginLoad] = useState(false);
  const [margin, setMargin] = useState("-7.5px");
  const [marginForm, setMarginForm] = useState("0px");
  const navigate = useNavigate();
  const inputRef = React.useRef(null);
  const passwordRef = React.useRef(null);
  const mediaMatch = window.matchMedia('(max-width: 600px)');
  const [matches, setMatches] = useState(mediaMatch.matches);
  
  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addEventListener("change", handler);
    return () => mediaMatch.removeEventListener("change", handler);
  });

  //
  useEffect(() => {
    if (matches) {
      setMargin("0px");
      setMarginForm("-20px");
    }
  }, [matches]);


  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Open Sans']
      }
    });
  }, []);

  const getName = useCallback(async (user) => {
    const docRef = doc(db, "users", user?.uid);
    // retry three times to get data from firestore
    for (let i = 0; i < 6; i++) {
      try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const name = await docSnap.data()["name"];
          return name
        } else {
          console.log("GetName, No such document!");
          await new Promise(r => setTimeout(r, 2000));
        }
      }
      catch (error) {
        console.log("error", error);
      }
    }
  }, []);

  useEffect(() => {
    // if (loading) {
    //   // maybe trigger a loading screen
    //   return;
    // }
    if (user) {
      if (user.emailVerified) {
        getName(user).then((name) => {
          if (name === "__init") navigate("/welcome");
          else navigate("/dashboard");
        }).catch(error => console.log("error", error));
      }
      else alert("Please verify your email address.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, loading]);

  const login = () => {
    logInWithEmailAndPassword(email, password);
    if (user) {
      if (user.emailVerified) navigate("/dashboard");
      else alert("Please verify your email address.");
    }
  }

  const loginGoogle = useCallback(async () => {
    try{
      setLoginLoad(true);
      await signInWithGoogle()
      if (user) {
          const name = await getName(user)
          if (name === "__init") navigate("/welcome");
          else navigate("/dashboard");
      }
      }
      catch(error){
        setLoginLoad(false)
        alert(error.message);
      }
    }, []);

  const loginApple = useCallback(async () => {
    try{
    setLoginLoad(true);
    await signInWithApple()
    if (user) {
      // getName(user).then((name) => {
        const name = await getName(user)
        if (name === "__init") navigate("/welcome");
        else navigate("/dashboard");
      // })
    }
    }
    catch(error){
      setLoginLoad(false)
      alert(error.message);
    }
  }, []);
  
  return (
    <div class="full-bleed">
      <header id="header" className="fixed-top"  style={{backgroundColor: accent_color}}>
        <div className="container d-flex align-items-center" style={{backgroundColor: accent_color}}>
          <a href="https://graham.marketinference.com" className="logo me-auto" style={{ marginLeft: margin, marginTop: headMargin }}>
            <strong className="h1title">Graham</strong></a>
        </div>
      </header>

      <div className="container ex-2-header" style={{ position: position}}>
        <div className="row">
          {loginLoad ? <LoadingAnimation /> :
            <div className="col-lg-12" style={{ position: position, top: signInTopMargin }}>
              <h1>Sign in</h1>
              <p>
                <span className="bodymyfont">No account? Please{" "}</span>
                <a className="white bodymyfont" href="./register">
                  Sign Up
                </a>
              </p>
              {/* Sign Up Form */}

              <div className="form-container" style={{ marginTop: marginForm }}>
                {/* Depending on the platform we show different login options */}
                <div className="form-group">
                  {platform === "web" ?
                    <div>
                      <button
                        style={{
                          backgroundImage: `url(${googleButton})`,
                          backgroundPosition: 'center',
                          backgroundSize: '60% auto',
                          backgroundRepeat: 'no-repeat',
                          backgroundColor: 'white',
                        }}
                        className="form-control-submit-button buttonmyfont my-button" onClick={() => loginGoogle()}>
                      </button>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <button
                        style={{
                          backgroundImage: `url(${appleButton})`,
                          backgroundPosition: 'center',
                          backgroundSize: '120% auto',
                          backgroundRepeat: 'no-repeat',
                          backgroundColor: 'white',
                        }}
                        className="form-control-submit-button buttonmyfont my-button" onClick={() => loginApple()}>
                      </button>
                    </div> : <div>{platform === "ios" ? <button
                      style={{
                        backgroundImage: `url(${appleButtonFull})`,
                        backgroundPosition: 'center',
                        backgroundSize: '60% auto',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: 'black',
                      }}
                      className="form-control-submit-button buttonmyfont my-button-solo" onClick={() => loginApple()}>
                    </button> : <button
                      style={{
                        backgroundImage: `url(${googleButtonFull})`,
                        backgroundPosition: 'center',
                        backgroundSize: '60% auto',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: 'white',
                      }}
                      className="form-control-submit-button buttonmyfont my-button-solo-google" onClick={() => loginGoogle()}>
                    </button>}</div>}

                  <div className="line-with-text" style={{ marginBottom: "6px" }}>
                    <span>or</span>
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control-input inputmyfont"
                      id="lemail"
                      required=""
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email"
                      ref={inputRef}
                    />
                    <div className="help-block with-errors" />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control-input inputmyfont"
                      id="lpassword"
                      required=""
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Password"
                      ref={passwordRef}
                    />
                    <div className="help-block with-errors" />
                  </div>
                  <div className="form-group">
                    <button className="form-control-submit-button buttonmyfont" onClick={() => login()}>
                      Sign in with email
                    </button>
                  </div>


                </div>
                <div className="form-message" >
                  <div id="lmsgSubmit" className="h3 text-center hidden" />
                </div>
                <a href="./reset" style={{ color: accent_color, fontSize: 12 }} >Forgot your password?</a>
              </div>
            </div>}
        </div>
      </div>
    </div>
  );
}

export default Login;