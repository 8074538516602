import React from "react";
import logo from "./assets/logo_roundwhite.png"

function DashboardMenu({ reset, logout, setShowSubscriptionModal, setShowInfoModal, setShowDeleteModal, subscribe_active, user }) {
  return (
          <div>
            {/* <img src={logo} alt="logo" style={{ width: "60px", margin: "auto", display: "block", paddingTop: 0.08*window.screen.availHeight, paddingBottom: "20px", marginBottom: "20px"  }} /> */}
            {/* <button onClick={() => onSetSidebarOpen(false)}>Close</button> */}
            <ul style={{ listStyle: "none",  paddingLeft: 0, paddingTop: "6rem"}}>
              <li> <button className="sidebar-button" onClick={() => setShowInfoModal(true)}>Info</button></li>
              <li><button className="sidebar-button" onClick={reset}>Restart</button></li>
              {subscribe_active === true ? <li><button className="sidebar-button" onClick={() => setShowSubscriptionModal(true)}>Subscription</button></li> : null}
              <li><button className="sidebar-button" onClick={() => setShowDeleteModal(true)}>Delete Account</button></li>
              <li><button className="sidebar-button" onClick={logout}>Logout</button></li>
            </ul>
          </div>
  );
}

export default DashboardMenu;