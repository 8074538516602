import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Zoom } from 'react-toastify';
import Subscribe from './Subscribe';

const welcome_msg = <div>Welcome to the Serena beta!<br></br> <br></br> Write a message about how you feel or what you would like to talk about and Serena will respond interactively. For a better experience, try to avoid simple yes/no answers.<br></br> <br></br> 
Occasionally, Serena can make wrong assumptions or misunderstand your messages. You can always regenerate a response if you find that it is not helpful or inappropriate. 
<b> Please notice that Serena should not be relied upon for medical advice!</b> <br></br> <br></br> We hope you enjoy talking to Serena &#128578; </div> ;

// const info_msg = <div> If you ever find that an answer is not helpful or inappropriate you can regenerate the response. Clicking "Restart" will delete the chat history and starts a new conversation.
//  </div> ;

const info_msg = welcome_msg;

const danger_msg = "Remember, I am not a human and I can not call for help. If you are in a potentially life-threatening situation get immediate emergency assistance by calling 911. If you or someone you know is suicidal or in emotional distress, contact 988 Suicide & Crisis Lifeline.";

const quota_msg = <div>You have reached the limit of free messages. To remove the limit, please consider a monthly subscription. <br></br><br></br> <Subscribe/> </div>;

const warn_msg = "Your message has been flagged as potentially harmful. Please write a new message.";

const timeout_msg = "Response is delayed due to very high demand, we are sorry for the inconvenience.";

const wait_msg = "Please wait until the model has generated a response."

const error_msg = "An error has occured, please reload the window"

const info = toastId => {
    if (!toast.isActive(toastId.current)) {
        toastId.current = toast(info_msg, {
            position: "top-center",
            hideProgressBar: false,
            autoClose: false,
            transition: Zoom
        });
    }
}

const welcome = toastId => {
    if (!toast.isActive(toastId.current)) {
        toastId.current = toast(welcome_msg, {
            position: "top-center",
            hideProgressBar: false,
            autoClose: false,
            transition: Zoom
        });
    }
}

const wait = toastId => {
    if (!toast.isActive(toastId.current)) {
        toastId.current = toast.warning(wait_msg, {
            position: "top-center",
            hideProgressBar: false,
            autoClose: 2000,
            transition: Zoom
        });
    }
}

const timeout = toastId => {
    if (!toast.isActive(toastId.current)) {
        toastId.current = toast.warning(timeout_msg, {
            position: "top-center",
            hideProgressBar: false,
            autoClose: 5000,
            transition: Zoom
        });
    }
}

const danger = (toastId) => {
    if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(danger_msg, {
            position: "top-center",
            hideProgressBar: false,
            autoClose: false,
            transition: Zoom
        });
    }
}

const error_reload = (toastId) => {
    if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(error_msg, {
            position: "top-center",
            hideProgressBar: false,
            autoClose: false,
            transition: Zoom
        });
    }
}

const toxic = (toastId) => {
    if (!toast.isActive(toastId.current)) {
        toastId.current = toast.warning(warn_msg, {
            position: "top-center",
            hideProgressBar: false,
            autoClose: false,
            transition: Zoom
        });
    }
}

const quota = (toastId) => {
    if (!toast.isActive(toastId.current)) {
        toastId.current = toast.warning(quota_msg, {
            position: "top-center",
            autoClose: false,
            hideProgressBar: true,
            transition: Zoom
        });
    }
}

export {
    quota,
    danger,
    info,
    toxic,
    welcome,
    timeout,
    wait,
    error_reload
};